<template>
  <div class="page">
    <el-dialog title="" :width="width" :visible.sync="dialogVisible" top="2vh" :before-close="handleClose" :fullscreen="fullscreen">
      <div class="content">
        <div class="title">服務條款與隱私協議</div>
        <p class="text">
          在註冊並使用 TOKENPAID 支付工具之前， 請您務必審慎閱讀、充分理解各條款內容。您的註冊和使用行為將被視為對以下聲明的接受和同意：</p>
        <p class="text" style="font-weight: bold;">用戶賬戶和安全：</p>
        <p class="text">● 用戶應自行負責妥善保管個人賬戶信息，包括但不限於賬號、密碼、私鑰等，不得將其提供給任何第三方。</p>
        <p class="text">● 用戶應當及時更新賬戶信息，並確保其賬戶信息的準確性和完整性。</p>
        <p class="text">● 用戶對其賬戶下的所有活動負責，包括但不限於交易、操作等。</p>
        <p class="text" style="font-weight: bold;">支付功能：</p>
        <p class="text">● TOKENPAID 提供錢包創建和數字資產收取和轉移等功能，並不提供任何形式交易和法幣兌換。</p>
        <p class="text">●
          用戶在使用平臺時應自行承擔風險，併對支付密碼和交易密碼的安全負責。如因用戶原因導緻密碼洩露或賬戶被盜用，用戶應立即通知我們以保護賬戶安全。</p>
        <p class="text">● 用戶在進行數字資産交易時應充分了解區塊鏈不穩定因素和風險，並自行承擔收取和轉移可能産生的風險和損失。</p>
        <p class="text">● 用戶合理使用平臺，請勿違反當地法律法規。</p>
        <p class="text" style="font-weight: bold;">免責聲明範圍：</p>
        <p class="text">●
          本平臺僅為用戶提供錢包資產轉移的渠道，不對用戶因使用本平臺服務而産生的任何直接或間接損失承擔責任，包括但不限於交易風險、信息洩露、網絡故障等情況。</p>
        <p class="text">● 本平臺不承擔任何用戶之間或用戶與第三方之間因數字資産交易而産生的糾紛責任。</p>
        <p class="text">● 本平臺不承擔任何用戶因个人触犯法律发表而引发的民事刑事责任。</p>
        <p class="text">● 本平臺不承擔任何用戶因個人原因觸犯當地法律法規而引發的民事刑事責任。</p>
        <p class="text" style="font-weight: bold;">服務變更和中斷：</p>
        <p class="text">●
          本平臺保留隨時變更、中斷或終止部分或全部服務的權利，並對此不承擔任何責任。如平臺服務暫停後，用戶不受任何影響，可完全自行在區塊鏈鏈上所處理個人資産。</p>
        <p class="text">● 在可能的情況下，本平臺將儘力提前通知用戶有關服務變更或中斷的信息。併在恢復後嚮用戶說明原因。</p>
        <p class="text" style="font-weight: bold;">信息共享與披露：</p>
        <p class="text">●
          平臺不會收集用戶的個人信息，如需用於提供服務、改善用戶體驗和保護用戶安全等將會徵得用戶要求並授權的前提下收集用戶信息，併且採取合理的安全措施保護用戶信息的安全。</p>
        <p class="text">● 我們可能會使用 Cookie
          和其他技術收集用戶信息，用於個性化服務和行為分析。用戶可以根據自己的需求設置瀏覽器拒絕Cookie，但可能會影響部分服務的正常使用。</p>
        <p class="text">● 用戶享有隱私權，併有權要求查看、更正或刪除自己的個人信息。</p>
        <p class="text" style="font-weight: bold;">合規渠道和操作：</p>
        <p class="text">● 用戶不應通過第三方渠道下載安裝APP，警惕來源未知的軟件風險，由此帶來的損失應視為用戶操作不當，自行負責。</p>
        <p class="text">● 平臺客服不會蒐集用戶電話和地址，凡是以平臺名義的來電，警惕虛假詐騙電話。</p>
        <p class="text">● 任何匿名的電子郵件、社交軟件聯繫用戶索要賬號資料均不可信。</p>
        <p class="text" style="font-weight: bold;">法律適用和爭議解決：</p>
        <p class="text">●
          我們將盡力保護用戶信息安全，但不對用戶因自身操作不當導致信息泄露或被第三方獲取而造成的損失負責。用戶應妥善保管個人信息，避免將個人信息泄露給他人。</p>
        <p class="text">● 本服務條款和隱私政策應適用於與本平臺服務相關的一切法律關繫，並受到適用法律的約束。</p>
        <p class="text">● 任何因服務條款和隱私政策引起的爭議，應當通過友好協商解決。如果無法協商解決，則提交至相關法院訴訟解決。</p>
        <p class="text" style="font-weight: bold;">其他條款：</p>
        <p class="text">● 本服務條款和隱私政策標題僅爲方便起見，不影響其解釋和適用。</p>
        <p class="text">● 任何單個或部分無效不影響其餘部分的有效性。</p>
        <p class="text">● 請您在注冊使用本平臺服務之前仔細閱讀本聲明。如果您不同意本聲明的任何內容，請立即停止使用本平臺服務。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">關 閉</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style scoped>
.page >>> .el-dialog__body {
  padding: 0 20px;
  color: #000000;
}
.content {
  margin-bottom: 80px;
}

.title {
  font-size: 30px;
  font-weight: 600;
  color: #101010;
  margin-top: 60px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  line-height: 27px;
  margin: 20px 0;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .content {
    padding: 0 10px;
  }

  .title {
    font-size: 20px;
    margin-top: 40px;
  }
  .text {
    font-size: 14px;
  }
}
</style>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      width: "100px",
      fullscreen: false,
    }
  },
  methods: {

    init() {
      var screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        this.fullscreen = true;
      } else if (screenWidth <= 992) {
        this.fullscreen = false;
        this.width = "80%"
      } else {
        this.fullscreen = false;
        this.width = "60%"
      }
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
}
</script>
