<template>
  <div>
    <div class="header-bg">
      <div class="container">
        <div class="logo">
          <div class="img"><img src="../assets/logo.png" width="100%" alt=""></div>
          <div class="name">TOKENPAID</div>
        </div>
      </div>
      <div class="container homeHeader">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <div class="titleTxt">專注於提供區塊鏈便捷操作解決方案</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12">
            <div class="subTxt">操作簡單，安全易用，鏈上交互</div>
          </el-col>
        </el-row>
        <div class="btns">
          <div class="btn home-download-btn" @click="download()">
            <i></i>&nbsp;APP下載
          </div>
          <div class="btn home-service-btn" @click="service()">
            <i></i>&nbsp;聯繫客服
          </div>
        </div>
        <div class="home-app-img">
          <img src="../assets/phonebg.png" width="50%" alt="">
        </div>
      </div>
    </div>
    <div class="homeecology">
      <div class="container">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="14" :lg="14">
            <div class="info">
              TOKENPAID 是一款去中心化應用的DAPP，旨在為用戶提供低價、安全、便捷操作虛擬資產的解決方案。我們深知用戶隱私的重要性，利用區塊鏈的匿名的設計，無需擔心身份信息洩露及不受任何第三方的幹擾。
            </div>
            <div class="subinfo">
              依託于波場 (Tron) 區塊鏈技術，支持在 Tron 鏈上的 USDT 轉出和接收，支持 BASE58 和 HEX 的錢包地址以及各大交易所的中心化錢包地址。極大利用了 Tron 高速、穩定、低價的優勢，能夠為用戶提供更具成本效益的轉賬服務。
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="10" :lg="10">
            <img src="../assets/ecologyrun.png" class="ecology-pc">
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="homedevelop ">
      <div class="container">
        <div class="develop-title">核心優勢</div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <div class="develop-btn" style="margin-bottom: 10px;">區塊鏈技術</div>
            <p class="develop-text">依托區塊鏈技術，利用其去中心化、透明和不可篡改的特性，確保所有交易都具有高度的安全性和可信度，所有交易結果鏈上可查。</p>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <div class="develop-btn" style="margin-bottom: 10px;">安全性</div>
            <p class="develop-text">用戶賬戶所有資金都存儲於區塊鏈上，非存儲在 TOKENPAID 服務器上，TOKENPAID 隻協助實現便捷轉出，所有交易鏈上可查，用戶可以獨立於 TOKENPAID 平臺，在鏈上自主操作自己賬戶資金。</p>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <div class="develop-btn" style="margin-bottom: 10px;">匿名性</div>
            <p class="develop-text">TOKENPAID 重視用戶隱私，所有交易均為匿名，用戶無需提供任何個人信息即可進行轉賬，確保個人隱私不受侵犯。</p>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <div class="develop-btn" style="margin-bottom: 10px;">低成本</div>
            <p class="develop-text">TOKENPAID 利用 TRON 網絡的優勢大幅降低了手續費用，相比傳統方式，成本更低，為用戶節節約成本的同時提供良好的體驗。</p>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <div class="develop-btn" style="margin-bottom: 10px;">快速交易</div>
            <p class="develop-text">通過優化區塊鏈網絡，TOKENPAID 實現了交易的即時確認，使用戶能夠享受高效快捷的轉賬體驗。</p>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <div class="develop-btn" style="margin-bottom: 10px;">全球覆蓋</div>
            <p class="develop-text">利用區塊鏈的全球網絡，用戶可以輕鬆進行跨國交易，無需擔心匯率轉換和繁瑣的國際轉出手續。身處任何國家，無論鏈上還是線下任意操作您的資產。</p>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="homefollow">
      <div class="container">
        <div class="flowTitle">關注我們</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <a class="follow-1" href="https://twitter.com/TokenPay_TP" target="_blank"></a>
          </el-col>
          <el-col :span="8">
            <a class="follow-2" href="https://t.me/TokenPay_cn" target="_blank"></a>
          </el-col>
          <el-col :span="8">
            <a class="follow-3" href="mailto:tokenpaid@proton.me" target="_blank"></a>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="footer">
      <div class="container">
        <el-row :gutter="20">
          <el-col :span="12" style="padding-left:20px">
            Copyright © 2020-{{getYear()}} TokenPaId
          </el-col>
          <el-col :span="12" class="right point" style="padding-right:20px">
            <span @click="policy()">服務條款與隱私協議</span>
          </el-col>
        </el-row>
      </div>
    </div>
    <Policy ref="Policy"></Policy>
    <PageLoading ref="PageLoading" v-if="loading"></PageLoading>
    <Download ref="Download"></Download>
  </div>
</template>
<style scoped>
.logo {
  display: flex;
  margin: 20px 0;
  padding: 10px;
  text-align: left;
}

.logo .img {
  width: 25px;
}

.logo .name {
  flex: 1;
  font-size: 24px;
  font-weight: bold;
  color: #3da1d3;
}

.homeHeader {
  background: url('~@/assets/phonebg.png') no-repeat 100% / contain;
  background-position: 660px 0px;
  background-size: 25%;
}

.header-bg {
  background: no-repeat 100%;
  background-image: url('~@/assets/headbg.png');
  background-position: 0 57px;
  background-size: 100% 100%;
}

.titleTxt {
  font-size: 54px;
  padding-top: 80px;
  font-weight: bold;
}

.subTxt {
  font-size: 26px;
  color: #555;
  padding: 50px 0;
}

.btns {
  display: flex;
  align-items: center;
}

.btn {
  display: inline-block;
  width: 174px;
  height: 52px;
  height: 58px;
  line-height: 58px;
  text-align: center;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  background: #3d8fb8;
  border: 1px solid #3d8fb8;
  margin-right: 18px;
  cursor: pointer;
}

.home-app-img {
  display: none;
}

.home-download-btn i {
  display: inline-block;
  width: 28px;
  height: 24px;
  background: url('~@/assets/phone.png') no-repeat center 100% / contain;
  vertical-align: text-bottom;
}

.home-service-btn i {
  display: inline-block;
  width: 28px;
  height: 24px;
  background: url('~@/assets/service.png') no-repeat center 100% / contain;
  vertical-align: text-bottom;
}

.homeecology {
  background: url('~@/assets/ecologybg.png') no-repeat 100% / cover;
  background-position: top;
  color: #fff;
  box-sizing: inherit;
}

.ecology-pc {
  width: 120%;
  margin-top: 50px;
  margin-left: -260px;
}

.info {
  font-size: 24px;
  font-weight: 500;
  line-height: 42px;
  margin: 70px 0;
}

.subinfo {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 63px;
}

.develop-title {
  margin: 60px 0;
  font-size: 32px;
  font-weight: 500;
  color: #101010;
}

.develop-btn {
  outline: none;
  text-align: center;
  width: 160px;
  height: 46px;
  line-height: 45px;
  color: #3d8fb8;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #000;
  padding: 0;
  cursor: pointer;
}

.develop-btn:hover {
  background-color: #3d8fb8;
  color: #fff;
}

.homefollow {
  padding: 74px 0 68px;
  background: url('~@/assets/followbg.png') no-repeat 100% / cover;
}

.flowTitle {
  position: relative;
  font-size: 32px;
  color: #101010;
  line-height: 45px;
  font-weight: 500;
  margin: 0 0 93px;
}

.follow-1 {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background: url('~@/assets/x.png') no-repeat 100% / contain;
  cursor: pointer;
}

.follow-2 {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background: url('~@/assets/telegram.png') no-repeat 100% / contain;
  cursor: pointer;
}

.follow-3 {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background: url('~@/assets/email.png') no-repeat 100% / contain;
  cursor: pointer;
}

.follow-4 {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background: url('~@/assets/discord.png') no-repeat 100% / contain;
  cursor: pointer;
}

.footer {
  border-top: 1px solid #eaeaea;
  font-size: 14px;
  padding: 20px 0;
}

.right {
  text-align: right;
}

.point {
  cursor: pointer;
}

.develop-text {
  line-height: 25px;
  font-size: 14px;
  margin: 30px 0;
}

@media screen and (max-width: 1921px) {
  .header-bg {
    background-size: contain;
  }
  .homeHeader {
    height: 620px;
    background-position: 600px 80px;
    background-size: 35%;
  }
  .ecology-pc {
    width: 120%;
    margin-top: 100px;
    margin-left: -50px;
  }
}

@media screen and (min-width: 1200px) {
  .header-bg {
    background-size: contain;
  }
  .homeHeader {
    height: 620px;
    background-position: 660px 80px;
    background-size: 30%;
  }
  .ecology-pc {
    width: 120%;
    margin-top: 50px;
    margin-left: -140px;
  }
}

@media screen and (max-width: 1024px) {
  .header-bg {
    background-size: 120% auto;
    background-size: 100% 100%;
    background-position: 0 30px;
  }
  .homeHeader {
    height: 620px;
    background-position: 600px 80px;
    background-size: 35%;
  }
  .ecology-pc {
    width: 120%;
    margin-top: 50px;
    margin-left: -140px;
  }
}

@media screen and (max-width: 992px) {
  .header-bg {
    background-image: none !important;
    background-color: #fafafb;
    height: auto;
    padding: 20px 0 0;
    text-align: center;
  }

  .homeHeader {
    height: auto;
    background-image: none !important;
  }

  .titleTxt {
    font-size: 40px;
    padding-top: 100px;
    font-weight: bold;
  }

  .home-app-img {
    display: block;
    margin: 20px 0;
  }

  .btns {
    justify-content: center;
  }

  .btn {
    margin: 10px !important;
  }

  .ecology-pc {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .titleTxt {
    font-size: 30px;
    padding-top: 20px;
    font-weight: bold;
    padding: 0 10px;
  }

  .subTxt {
    font-size: 24px;
    color: #555;
    padding: 30px 0;
  }

  .homeHeader {
    height: auto;
    background-image: none !important;
  }

  .btns {
    justify-content: center;
  }

  .btn {
    margin: 10px !important;
  }

  .homeecology {
    text-align: center;
    padding-top: 60px;
  }

  .ecology-pc {
    display: none;
  }

  .info {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    padding: 0 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .subinfo {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 63px;
  }

  .develop-title {
    margin: 20px 0;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
  }

  .develop-btn {
    width: 220px;
    margin: 0 auto;
  }

  .develop-text {
    line-height: 25px;
    font-size: 14px;
    margin: 10px 0;
    max-width: none !important;
    text-align: center;
  }

  .flowTitle {
    font-size: 28px;
    margin-bottom: 40px;
    text-align: center;
  }

  .homefollow {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
</style>
<script>
import Policy from "@/components/Policy";
import PageLoading from "@/components/PageLoading";
import Download from "@/components/Download";
export default {
  components: {
    Policy,
    PageLoading,
    Download,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    download() {
      this.$refs.Download.init();
    },
    policy() {
      this.$refs.Policy.init();
    },
    service() {
      this.loading = true;
      if (window.$chatwoot) {
        window.$chatwoot.toggle();
        this.loading = false;
        this.drawer = false;
        return;
      }
      const Chat_URL = process.env.VUE_APP_Chat;
      const g = document.createElement('script');
      const s = document.getElementsByTagName('script')[0];
      g.src = `${Chat_URL}/packs/js/sdk.js`;
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);
      g.onload = () => {
        window.chatwootSDK.run({
          websiteToken: 'SUrK7soCxPxVVWoXEPwLqfBo',
          baseUrl: Chat_URL
        });
        window.$chatwoot.toggle();

        window.addEventListener('chatwoot:ready', () => {
          this.loading = false;
        });
      };
    },
    getYear() {
      var date = new Date();
      return date.getFullYear();
    }
  }
}
</script>
