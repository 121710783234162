<template>
  <el-drawer title="APP下載" :size="size" :visible.sync="drawer" direction="rtl">
    <div class="page">
      <el-row :gutter="20" style="margin-bottom:20px">
        <el-col :span="24" style="text-align:center">
          <a :href="downloadUrl+'tokenpaid.apk'" target="_blank" class="download-btn pointer android">
            <i></i>Android 點此下載
          </a>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-bottom:20px">
        <el-col :span="24">
          <div class="note">
            <div class="text" style="margin-bottom:10px">
              <span class="symbol">*</span>
              下載前請認準官網地址，並警惕網絡證書安全性
            </div>
            <div class="text" style="margin-bottom:10px">
              <span class="symbol">*</span>
              不要相信陌生來電，不要暴露助記詞和私鑰
            </div>
            <div class="website-wrap">
              <img src="../assets/lock.png" class="lock">
              <div class="website">
                <img src="../assets/unlock.png" class="unlock">
                <span class="text">{{url}}</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-bottom:20px">
        <el-col :span="24" style="text-align:center">
          <a :href="url" target="_blank" class="download-btn pointer iphone">
            <i></i>IOS 點此打開應用網站
          </a>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-bottom:20px">
        <el-col :span="24">
          <div class="note">
            <div class="text" style="margin-bottom:10px">
              <span class="symbol">*</span>
              然後點擊底部中間“分享按鈕”
            </div>
            <div class="text" style="margin-bottom:10px">
              <span class="symbol">*</span>
              在彈出的窗口向上拉，選擇添加到“主畫面”即可
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24" style="text-align:center">
          <img class="ios" src="/files/iphone.png">
        </el-col>
      </el-row>
      <div style="height:80px"></div>
    </div>
  </el-drawer>
</template>
<style scoped>
.page {
  padding: 20px;
}

.ios {
  width: 300px;
  border-radius: 15px;
}

.logo {
  margin: 20px 0;
  text-align: left;
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
}

.download-btn {
  line-height: 1.2;
  outline: none;
  height: 48px;
  border-radius: 8px;
  color: #fff;
  margin-bottom: 5px;
  text-align: center;
}

.android {
  z-index: 10;
  color: #333;
  font-size: 20px;
  font-weight: 50;
  height: 48px;
}

.android i {
  display: inline-block;
  width: 22px;
  height: 25px;
  vertical-align: middle;
  margin-right: 0.5em;
  margin-bottom: 4px;
  background: url('~@/assets/android.png') no-repeat center 100% / contain;
}

.iphone {
  z-index: 10;
  color: #333;
  font-size: 20px;
  font-weight: 50;
  height: 48px;
}

.iphone i {
  display: inline-block;
  width: 22px;
  height: 25px;
  vertical-align: middle;
  margin-right: 0.5em;
  margin-bottom: 4px;
  background: url('~@/assets/ios.png') no-repeat center 100% / contain;
}

.scan {
  z-index: 10;
  color: #333;
  font-size: 16px;
  font-weight: 50;
  height: 48px;
}

.scan i {
  display: inline-block;
  width: 22px;
  height: 25px;
  vertical-align: middle;
  margin-right: 0.5em;
  margin-bottom: 4px;
  background: url('~@/assets/qrcode.png') no-repeat center 100% / contain;
}

.note {
  padding: 20px;
  background: #f0f4ff;
  border-radius: 12px 12px 0 0;
  position: relative;
}

.text {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #000;
}

.symbol {
  color: #e55036;
}

.website-wrap {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.website-wrap .lock {
  width: 28px;
  height: 23px;
}

.website {
  margin-left: 10px;
  width: 275px;
  height: 30px;
  background: #f0f4ff;
  border: 1px solid #d7d7d7;
  border-radius: 23px;
  display: flex;
  align-items: center;
}

.website .unlock {
  margin-left: 5px;
  width: 25px;
  height: 23px;
}
</style>
<script>
export default {
  data() {
    return {
      drawer: false,
      url: process.env.VUE_APP_BASE,
      downloadUrl: process.env.VUE_APP_Download,
      size: "",
    }
  },
  methods: {
    init() {
      var screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        this.size = "100%"
      } else if (screenWidth <= 992) {
        this.size = "560px"
      } else {
        this.size = "560px"
      }
      this.drawer = true;
    },
  }
}
</script>
