<template>
  <div class="overlay">
    <div class="overlay-text">
      <div class="overlay-icon"><i class="el-icon-loading"></i></div>
      <div class="overlay-title" v-if="title">{{title}}</div>
      <div class="overlay-subtitle" v-if="subtitle">{{subtitle}}</div>
      <div class="overlay-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(37, 37, 37, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-text {
  width: 100%;
  color: #c9c9c9;
  font-size: 40px;
  text-align: center;
  border-radius: 5px;
  margin: 0 20px;
}

.overlay-icon {
  color: #3d8fb8;
}

.overlay-title {
  padding-top: 10px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.overlay-subtitle {
  font-size: 14px;
  text-align: center;

  color: #9b9b9b;
  margin-bottom: 20px;
}

.overlay-content {
  font-size: 14px;
  text-align: center;
  color: #9b9b9b;
  margin-bottom: 20px;
}
</style>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    }
  }
}
</script>
